@import "src/styles/settings/typography";
@import "sizes";
@import "buttons";
@import "spinners";
@import "forms";
@import "snackbar";
@import "bottom-sheet";
@import "timepicker";
@import "card";
mat-sidenav-container > .mat-drawer-backdrop{
 position: fixed;
}
