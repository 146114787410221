@use '~@angular/material' as mat;
@import "variables";
@import "settings/typography";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core($gh-typography-config);


@include mat.all-component-themes($guard-hire-web-theme);
@import "utils/utils";
@import "components/components";
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  height: 100%;
  font-size: $base-font-size;
}

body {
  margin: 0;
  background: white;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
p{
  color: $text-color;
}

mat-error {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
