
snack-bar-container {
  user-select: none;
  .mat-button{
    line-height: 36px !important;
  }
  .mat-button-wrapper{
    line-height: 36px !important;
  }
  &.snackbar-success {
    background: $color-primary;
    color: white;
    .mat-button-wrapper{
      color: white !important;
    }
  }
  &.snackbar-error {
    background: $color-warn;
    color: white;
    .mat-button-wrapper{
      color: white !important;
    }
  }
}
