

.gh-card-btn {
  display: inline-block;
  height: 154px;
  box-shadow: $box-shadow !important;
  width: 100%;
  max-width: 160px;
  padding: 18px;
  line-height: 24px !important;
  white-space: normal !important;
  border-radius: 8px;
  margin: 0 10px;

  .mat-button-wrapper {
    font-size: rem(20);
    font-weight: 900;
  }

  .gh-card-btn__icon {
    position: absolute;
    bottom: 8px;
    left: 0px;
    font-size: 20px;
    right: 0px;
    margin: auto;
  }

  &:hover {
    .gh-card-btn__icon {
      animation-name: cardArrowAnimation;
      animation-duration: .9s;
      animation-fill-mode: initial;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

  }
}

.gh-card-btns-group {
  display: flex;
  justify-content: center;
  align-items: start;

  & > .gh-card-btn {
    margin-left: 23px !important;

    &:first-child {
      margin-left: 0px !important;
    }
  }
}

@keyframes cardArrowAnimation {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: #3B3E40;
}

button, a {

  &.mat-button, &.mat-stroked-button, &.mat-flat-button {
    font-weight: 500;
    font-size: rem(14);

    line-height: 50px;
  }

  &.mat-raised-button:not(.gh-card-btn) {
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.mat-stroked-button, &.mat-flat-button {
    border-radius: 50px;
  }

  &.mat-stroked-button:not(.mat-button-disabled) {
    border-color: #000000;
    background: white;

    &[color='warn'] {
      border-color: $color-warn;
    }

    &[color='primary'] {
      border-color: $color-primary;
    }

    &[color='accent'] {
      border-color: $color-accent;
    }
  }
}

a, button {
  &.w-100 {
    width: 100%;
    display: block;
  }
}

a.link {
  color: $color-primary;
  font-weight: 500;
  cursor: pointer;
  font-size: rem(16);

  &.disabled {
    opacity: 0.4;
    cursor: none;
  }
  mat-icon {
    vertical-align: middle;
    margin-right: 3px;
    font-size: 20px;
  }
}

.btn-absolute-icon {
  position: relative;

  mat-icon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }

  &--left {
    mat-icon {
      left: 20px;
    }
  }

  &--right {
    mat-icon {
      right: 20px;
    }
  }
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  line-height: 1em !important;

  strong {
    font-weight: 600;
  }
}
