@use '../../../node_modules/@angular/material/index' as mat;

.text-center {
  text-align: center;
}


$gh-typography-config: mat.define-typography-config(
  $headline: mat.define-typography-level(rem(26), rem(32), 900),
  $body-1: mat.define-typography-level(rem(16), rem(22), 400),
  $subheading-2: mat.define-typography-level(rem(18), rem(24), 700),
  $subheading-1: mat.define-typography-level(rem(16), rem(22), 400),
);
ul {
  padding-left: 1.6em;
  line-height: 24px;
  font-weight: 400;
  list-style-type: disc;
}
.color-black{
  color:black;
}
