@each $size, $length in $sizes {
  .text-#{$size} {
    font-size: $length !important;
  }
}

@each $size, $length in $spacers {
  .p-#{$size} {
    padding: $length !important
  }
  .pl-#{$size} {
    padding-left: $length !important
  }
  .pr-#{$size} {
    padding-right: $length !important
  }
  .pt-#{$size} {
    padding-top: $length !important
  }
  .pb-#{$size} {
    padding-bottom: $length !important
  }
  .px-#{$size} {
    padding-left: $length !important;
    padding-right: $length !important
  }
  .py-#{$size} {
    padding-bottom: $length !important;
    padding-top: $length !important
  }
  .m-#{$size} {
    margin: $length !important
  }
  .ml-#{$size} {
    margin-left: $length !important
  }
  .mr-#{$size} {
    margin-right: $length !important;
  }
  .mt-#{$size} {
    margin-top: $length !important;
  }
  .mb-#{$size} {
    margin-bottom: $length !important;
  }
  .mx-#{$size} {
    margin-left: $length !important;
    margin-right: $length !important
  }
  .my-#{$size} {
    margin-bottom: $length !important;
    margin-top: $length !important;
  }
}
