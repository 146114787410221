.mat-checkbox-layout{
  justify-content: start;
  align-items: start;
  .mat-checkbox-inner-container{
    margin: 0px;
    margin-top: 6px;
    margin-right: 8px;
  }
}
.mat-form-field {
  color: $text-color;
  mat-icon[matsuffix]{
    cursor: pointer;
    user-select: none;
  }
  .mat-form-field-infix{
    width: 100%;
  }
  &.mat-form-field-appearance-outline {
    .mat-form-field-subscript-wrapper {
      padding-left: 0 !important;
    }
    .mat-form-field-infix {
      border-top: none;
      padding: 1em 0 .75em 0;
    }
    .mat-form-field-outline-start {
      border-radius: 8px 0 0 8px;
    }
    .mat-form-field-outline-end {
      border-radius: 0 8px 8px 0;
    }
    .mat-form-field-prefix, .mat-form-field-suffix {
      top: .4em;

      .mat-icon:not(.mat-warn) {
        width: auto;
        height: auto;
        font-size: 120%;
        color: #CACCCF;
      }
    }

    .mat-form-field-outline {
      color: $form-color;
    }
  }
}

mat-label {
  color: $color-black60;
  font-weight: 400;
  font-size: 12px;
  display: block;
  line-height: 16px;
  letter-spacing: 0.2px;
}
