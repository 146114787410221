@use '~@angular/material' as mat;
@import 'settings/palette';
@import "settings/mixins";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$guard-hire-web-primary: mat.define-palette($md-ghpallete);
$guard-hire-web-accent: mat.define-palette(mat.$blue-palette,700);

// The warn palette is optional (defaults to red).
$guard-hire-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$guard-hire-web-theme: mat.define-light-theme((
  color: (
    primary: $guard-hire-web-primary,
    accent: $guard-hire-web-accent,
    warn: $guard-hire-web-warn,
  )
));


$text-color: #4A4A4A;
$text-label-color: #80858A;
$color-black60: #A0A4A8;
$base-font-size: 16px;
$box-shadow: 0px 12px 40px rgba(37, 40, 43, 0.32);
$max-width: 900px;
$border-green: #1AB759;
$cubic-transition: cubic-bezier(.25, .8, .25, 1);
$gray-gradient: linear-gradient(0deg, #F9F9FA, #F9F9FA), #E8EAED;
$color-primary: mat.get-color-from-palette($guard-hire-web-primary, 500);
$color-accent: mat.get-color-from-palette($guard-hire-web-accent, 600);
$color-warn: mat.get-color-from-palette($guard-hire-web-warn);
$form-color: #DBDDE0;


$color-icons: #130F26;

$navbar-height: 50px;

$sizes: (
  s: 8px,
  xs: 14px,
  m: 16px,
  l: 24px,
  xl: 32px,
  xxl: 64px,
);
$phone-width: 700px;
$spacers: (
  0: 0px,
  xs: 4px,
  s: 8px,
  m: 16px,
  l: 24px,
  xl: 34px,
  xxl: 44px,
  xxxl: 60px,
);


@import "settings/functions";


