.gray-divider{
  height: 10px;
  background: linear-gradient(0deg, #E8EAED, #E8EAED), #E8EAED;
}

.gray-divider--thin{
  height: 2px;
  background: linear-gradient(0deg, #E8EAED, #E8EAED), #E8EAED;
}
.w-100{
  width: 100%;
}
.gray-bg{
  background: $gray-gradient;
}

.mask-letter-spacing {
  letter-spacing: 3px;
}
